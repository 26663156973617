<template>
  <section class="prompt-manager">
    <div class="container">
      <SpinnerLoading v-if="!assistant" />
      <div v-else class="row justify-content-center">
        <div class="col-md-6 mt-6">
          <h2>Configuración inicial</h2>
          <p class="description mb-5">Agrega un conjunto de instrucciones personalizadas para orientar las respuestas de la inteligencia articial de tu chatbot.</p>
          <div class="label-box align-items-end mb-3">
            <label for="" class="mb-1">Prompt</label>
            <span>{{ assistant.ia_configs.custom_prompt?.length || 0 }} / 3000</span>
          </div>
          <textarea
            id=""
            cols="30"
            rows="10"
            placeholder="Describe las instrucciones"
            :class="{ 'mb-3': true, active: assistant.ia_configs.custom_prompt?.length > 0 }"
            v-model="assistant.ia_configs.custom_prompt"
            maxlength="3000"
          />
          <label class="mb-3" for="">Comportamiento de la IA</label>
          <div class="two_box mb-5">
            <select
              class="w-100"
              id=""
              v-model="assistant.ia_configs.answer_length"
            >
              <option :value="null">Largo de respuesta</option>
              <option
                v-for="(item, index) in answer_length"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </select>
            <select
              class="w-100"
              v-model="assistant.ia_configs.answer_tone"
            >
              <option :value="null">Tono de respuesta</option>
              <option
                v-for="(item, index) in answer_tone"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <button class="btn btn-base md" @click="save">Guardar</button>
        </div>
        <div class="col-lg-4 mt-6">
          <label for="" class="mb-3">Ejemplo de instrucciones:</label>
          <p><i>Eres un chatbot llamado Marin, experto en atención al cliente el cual puede resolver todas tus dudas.</i></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      prompt_description: '',
      answer_length: [
        { value: "corta", name: 'Respuesta corta' },
        { value: 'media', name: 'Respuesta media' },
        { value: 'larga', name: 'Respuesta larga' },
      ],
      answer_tone: [
        { value: "informal", name: 'Tono informal' },
        { value: "amigable", name: 'Tono amigable' },
        { value: "directo", name: 'Tono directo' },
        { value: "seguro", name: 'Tono seguro' },
        { value: "profesional", name: 'Tono profesional' },
      ],
      assistant: null,
    };
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapState(['selected_bot']),

    assistantId() {
      return this.selected_bot?.business_type?.assistant_id;
    },
  },
  methods: {
    async initialize() {
      let assistant = await this.$store.dispatch(
        'assistantsModule/listOne',
        this.assistantId,
      );
      if (!assistant.ia_configs) {
        assistant.ia_configs = {};
      }
      this.assistant = assistant;
      console.log("assistant->", this.assistant);
      
    },
    async save() {
      try {
        this.$swal({
          icon: 'info',
          title: 'Actualizando instrucciones..',
        });
        this.$swal.showLoading();

        await this.$store.dispatch('assistantsModule/update', { assistant_id: this.assistantId, payload: this.assistant });

        this.$swal({
            icon: 'success',
            title: 'Actualización exitosa',
          });
      } catch (error) {
        console.log("Error: ", error);
        this.$swal({
          icon: 'error',
          title: 'Error al actualizar',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prompt-manager {
  width: 100%;
  padding-bottom: 5rem;

  .description {
    width: 80%;
    
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .btn-base {
    width: 120px;
  }
}
</style>
